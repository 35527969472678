// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "J_fQ d_fQ d_bz d_bH d_bJ";
export var navbarDividedRight = "J_fR d_fR d_bz d_bJ";
export var menuDesign6 = "J_wf d_fM d_bz d_dw d_bP d_bJ";
export var menuDesign7 = "J_wg d_fM d_bz d_dw d_bP d_bJ";
export var menuRight = "J_wh d_fM d_bz d_dw d_bP d_bJ";
export var menuLeft = "J_wj d_fM d_bz d_dw d_bP d_bJ";
export var menuCenter = "J_wk d_fN d_fM d_bz d_dw d_bP d_w d_bD d_bJ";
export var menuDivided = "J_m9 d_fN d_fM d_bz d_dw d_bP d_w d_bD";
export var menuDesign5 = "J_nl d_fP d_fM d_bz d_dw d_bP d_bJ";
export var isBurger = "J_wl";
export var navbarItem = "J_nb d_bx";
export var navbarLogoItemWrapper = "J_fY d_fY d_bC d_bP";
export var burgerToggleVisibleOpen = "J_wm d_gd d_by d_Z d_bs";
export var burgerToggleVisible = "J_wn d_gd d_by d_Z d_bs";
export var burgerToggle = "J_wp d_gd d_by d_Z d_bs d_Z";
export var burgerToggleOpen = "J_wq d_gd d_by d_Z d_bs";
export var burgerInput = "J_wr d_f2 d_w d_H d_by d_cc d_dl d_bd d_0 d_8 d_7 d_4 d_9";
export var burgerIcon = "J_ws d_f3 d_w d_H";
export var burgerLine = "J_wt d_f1";
export var burgerMenuDesign6 = "J_wv d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign7 = "J_ww d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuLeft = "J_wx d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign5 = "J_wy d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuRight = "J_wz d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuCenter = "J_wB d_f9 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDivided = "J_wC d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var secondary = "J_wD d_bC d_bP";
export var compact = "J_wF";
export var navDivided = "J_wG";
export var staticBurger = "J_wH";
export var menu = "J_wJ";
export var navbarDividedLogo = "J_wK";
export var nav = "J_wL";
export var fixed = "J_wM";