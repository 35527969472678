// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "G_v4 d_bD";
export var storyRowWrapper = "G_hx d_hx d_bK";
export var storyLeftWrapper = "G_v5 d_bz d_bP";
export var storyWrapperFull = "G_v6 d_cD";
export var storyWrapperFullLeft = "G_qZ d_cD d_bz d_bP d_bD";
export var contentWrapper = "G_mv d_hy";
export var storyLeftWrapperCenter = "G_v7 d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "G_v8 d_hF";
export var storyHeader = "G_v9 d_hD d_w d_cs";
export var storyHeaderCenter = "G_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "G_hB d_hB d_by d_dw";
export var storyBtnWrapper = "G_wb d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "G_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "G_rj d_fg d_Z";
export var imageWrapperFull = "G_wc d_w d_H d_bf d_Z";
export var SubtitleSmall = "G_pd C_pd C_tz C_tM";
export var SubtitleNormal = "G_pf C_pf C_tz C_tN";
export var SubtitleLarge = "G_pg C_pg C_tz C_tP";
export var textLeft = "G_dv";
export var textCenter = "G_dw";
export var textRight = "G_dx";