// extracted by mini-css-extract-plugin
export var carouselContainer = "M_wR d_w d_H d_bf d_Z";
export var carouselContainerCards = "M_wS M_wR d_w d_H d_bf d_Z";
export var carouselContainerSides = "M_wT d_w d_H d_Z";
export var prevCarouselItem = "M_wV d_w d_H d_0 d_k";
export var prevCarouselItemL = "M_wW M_wV d_w d_H d_0 d_k";
export var moveInFromLeft = "M_wX";
export var prevCarouselItemR = "M_wY M_wV d_w d_H d_0 d_k";
export var moveInFromRight = "M_wZ";
export var selectedCarouselItem = "M_w0 d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "M_w1 M_w0 d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "M_w2 M_w0 d_w d_H d_Z d_bf";
export var nextCarouselItem = "M_w3 d_w d_H d_0 d_k";
export var nextCarouselItemL = "M_w4 M_w3 d_w d_H d_0 d_k";
export var nextCarouselItemR = "M_w5 M_w3 d_w d_H d_0 d_k";
export var arrowContainer = "M_w6 d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "M_w7 M_w6 d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "M_w8 M_w7 M_w6 d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "M_w9 M_w6 d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "M_xb";
export var nextArrowContainerHidden = "M_xc M_w9 M_w6 d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "M_kG d_0";
export var prevArrow = "M_xd M_kG d_0";
export var nextArrow = "M_xf M_kG d_0";
export var carouselIndicatorContainer = "M_xg d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "M_xh d_w d_bz d_bF";
export var carouselText = "M_xj d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "M_xk M_xj d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "M_xl d_b7";
export var carouselIndicator = "M_xm M_xl d_b7";
export var carouselIndicatorSelected = "M_xn M_xl d_b7";
export var arrowsContainerTopRight = "M_xp d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "M_xq d_0 d_bl d_bC";
export var arrowsContainerSides = "M_xr d_0 d_bl d_bC";
export var smallArrowsBase = "M_xs d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "M_xt M_xs d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "M_xv M_xt M_xs d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "M_xw M_xs d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "M_xx M_xw M_xs d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "M_n9";
export var iconSmall = "M_xy";
export var multipleWrapper = "M_xz d_bC d_bF d_bf";
export var multipleImage = "M_xB d_bC";
export var sidesPrevContainer = "M_xC M_xt M_xs d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "M_xD M_xt M_xs d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";