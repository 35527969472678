// extracted by mini-css-extract-plugin
export var alignLeft = "y_rd d_bG";
export var alignCenter = "y_bP d_bD";
export var alignRight = "y_rf d_bH";
export var textAlignLeft = "y_s2";
export var textAlignCenter = "y_s3";
export var textAlignRight = "y_s4";
export var hoursInnerWrapperAlt = "y_s5 d_fb d_P d_Z d_bz d_bD d_bP d_dj";
export var hoursInnerWrapperLeft = "y_s6 d_fc d_fb d_P d_Z d_bz d_bD d_bP d_b5 d_dj";
export var hoursText = "y_s7 d_dw";
export var titleMargin = "y_s8 d_cw";
export var hoursInnerInnerWrapper = "y_s9 d_cz";