// extracted by mini-css-extract-plugin
export var quoteParagraphLeft = "D_vM d_gS d_cw d_dv";
export var quoteParagraphCenter = "D_gT d_gT d_cw d_dw";
export var quoteParagraphRight = "D_gV d_gV d_cw d_dx";
export var quoteRowLeft = "D_vN d_bG";
export var quoteRowCenter = "D_vP d_bD";
export var quoteRowRight = "D_vQ d_bH";
export var quoteWrapper = "D_gQ d_gQ d_w d_cD d_bz d_bP d_bD";
export var quoteContentWrapper = "D_gR d_gR";
export var quoteExceptionSmall = "D_vs C_vs";
export var quoteExceptionNormal = "D_vt C_vt";
export var quoteExceptionLarge = "D_vv C_vv";
export var quoteAuthorExceptionSmall = "D_vw C_vw";
export var quoteAuthorExceptionNormal = "D_vx C_vx";
export var quoteAuthorExceptionLarge = "D_vy C_vy";