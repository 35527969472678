// extracted by mini-css-extract-plugin
export var socialContainer = "F_vR";
export var instagramLink = "F_vS";
export var socialWrapperLeft = "F_qz d_dC d_Z d_bz d_bJ d_bp d_bG";
export var socialContentContainer = "F_vT";
export var socialContentContainerFull = "F_vV";
export var instagramContainer = "F_vW";
export var twitterContainer = "F_vX";
export var facebookContainer = "F_vY";
export var socialErrorContainer = "F_vZ";
export var facebookContainerWide = "F_v0";
export var twitterContainerWide = "F_v1";
export var socialParagraphCenter = "F_v2";
export var instaWrapper = "F_v3";
export var SubtitleSmall = "F_pd C_pd C_tz C_tM";
export var SubtitleNormal = "F_pf C_pf C_tz C_tN";
export var SubtitleLarge = "F_pg C_pg C_tz C_tP";
export var textLeft = "F_dv";
export var textCenter = "F_dw";
export var textRight = "F_dx";